import { ProcessedFile } from "@hlcr/mui/Upload";
import { useIntl } from "@hlcr/ui/Intl";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";

import { CriteriaGradingCatalogDto, CriteriaGradingCatalogItemDto } from "components/Solution/CriteriaGradingCatalogDto";
import { CriteriaGradingComponent } from "components/Solution/CriteriaGradingComponent";
import { UserFlag } from "components/Solution/UserFlagsBox";
import { PointSliderForm } from "grading/PointSliderForm/PointSliderForm";
import { WriteUpForm } from "grading/WriteUpForm/WriteUpForm";
import { GradingInstructions } from "views/Teacher/GradingInstructions";

export const GradingForm = ({
	isWriteupBased,
	maxPoints,
	flagPoints,
	writeupWeight,
	writeupGrade,
	writeup,
	file,
	criteriaGradingCatalog,
	criteriaList,
	setCriteriaList,
	isTeacher,
	isCriteriaGradingMode,
	addGrade,
	submitting,
	sliderDisabled,
	handleWriteupGradeChange,
	handleIsAddGradeActiveChange,
	handleWriteupChange,
	handleOnFileChange,
	hasErrors,
	gradingInstructions,
	userFlags,
	solutionId,
	eventId,
}: {
	isWriteupBased: boolean;
	maxPoints: number;
	flagPoints: number;
	writeupWeight: number;
	writeupGrade: number;
	writeup: string;
	file: ProcessedFile;
	criteriaGradingCatalog: CriteriaGradingCatalogDto | undefined;
	criteriaList: CriteriaGradingCatalogItemDto[];
	setCriteriaList: (criteria: CriteriaGradingCatalogItemDto[]) => void;
	isTeacher: boolean;
	isCriteriaGradingMode: boolean;
	addGrade: boolean;
	submitting: boolean;
	sliderDisabled: boolean;
	handleWriteupGradeChange: (value: number) => void;
	handleIsAddGradeActiveChange: (isActive: boolean) => void;
	handleWriteupChange: (writeup: string) => void;
	handleOnFileChange: (file: ProcessedFile | undefined) => void;
	hasErrors: boolean;
	gradingInstructions: any;
	userFlags: UserFlag[];
	solutionId: number;
	eventId: number;
}) => {
	const intl = useIntl();
	const localStoreIdentifier = `${eventId}_${solutionId}_grading`;

	useEffect(() => {
		if (!isCriteriaGradingMode) {
			const savedWriteup = localStorage.getItem(`${localStoreIdentifier}_writeup`);
			const savedGrade = localStorage.getItem(`${localStoreIdentifier}_points`);
			if (savedWriteup) {
				handleWriteupChange(savedWriteup);
			}
			if(savedGrade) {
				handleWriteupGradeChange(Number(savedGrade));
			}
		}
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!isCriteriaGradingMode) {
				localStorage.setItem(`${localStoreIdentifier}_writeup`, writeup);
				localStorage.setItem(`${localStoreIdentifier}_points`, writeupGrade.toString());
			}
		}, 100);

		return () => clearTimeout(timeout);
	}, [writeup, writeupGrade]);

	return (
		<Box display="flex">
			<Box flexGrow={1}>
				<form onSubmit={(e) => e.preventDefault()}>
					{isCriteriaGradingMode && criteriaGradingCatalog && <CriteriaGradingComponent criteriaList={criteriaList} setCriteriaList={setCriteriaList} />}
					{isCriteriaGradingMode && !criteriaGradingCatalog && (
						<Typography variant={"body1"} color={"error"}>
							There are no grading criteria for this challenge. Please change the grading mode of the challenge or add the criteria manually in the database.
						</Typography>
					)}
					{!isCriteriaGradingMode && isWriteupBased && (
						<>
							<h4>{intl.fm("teacher.solution.grading.title")}</h4>
							<PointSliderForm
								maxPoints={maxPoints}
								flagPoints={flagPoints}
								writeUpWeight={writeupWeight}
								writeUpGrade={writeupGrade}
								isAddGradeActive={addGrade}
								disabled={sliderDisabled}
								onWriteUpGradeChange={handleWriteupGradeChange}
								onIsAddGradeActiveChange={handleIsAddGradeActiveChange}
							/>
						</>
					)}
					{!isCriteriaGradingMode && (
						<WriteUpForm
							writeUpText={writeup}
							writeUpFile={file}
							isTeacher={isTeacher}
							isSubmitting={submitting}
							onWriteUpChange={handleWriteupChange}
							onFileChange={handleOnFileChange}
							hasError={hasErrors}
						></WriteUpForm>
					)}
				</form>
			</Box>
			<GradingInstructions userFlags={userFlags} gradingInstruction={gradingInstructions} />
		</Box>
	);
};
