import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import * as PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import solutionStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionStyle";
import { Accordion } from "@hlcr/mui";
import { Button } from "@hlcr/mui/Button";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { UserFlagsBox } from "components/Solution/UserFlagsBox";
import { withIntl } from "@hlcr/ui/Intl";
import "rc-slider/assets/index.css";
import { fetchHelp } from "variables/helpPage";
import { GRADING_MODES } from "models/EventUnit";
import { GradingForm } from "components/Solution/GradingForm";
import { SolutionSubmissionForm } from "components/Solution/SolutionSubmissionForm";
import { useCriteriaGradingCatalog } from "components/Solution/criteriaGradingCatalog.hooks";
import { isEqual } from "lodash";
import { getEmptyCriteriaList } from "components/Solution/CriteriaGradingCatalogDto";

const SubmitDialog = (props) => {
	const isCriteriaGradingMode = GRADING_MODES[props.eventUnit?.grading] === GRADING_MODES.CRITERIA;
	const isTeacher = props.isTeacher;
	const criteriaCatalog = useCriteriaGradingCatalog(props.solutionId, isCriteriaGradingMode, isTeacher);
	return <WrappedSolutionSubmitDialog {...props} criteriaCatalog={criteriaCatalog} isCriteriaGradingMode={isCriteriaGradingMode} />;
};

class WrappedSolutionSubmitDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			writeup: null,
			flag: null,
			writeupGrade: 0,
			submitting: false,
			addGrade: true,
			formDirty: false,
			criteriaList: props.criteriaCatalog?.criteriaGradingCatalogItems,
		};
	}

	componentDidUpdate(prevProps) {
		const { isOpen, lastTeacherSolutionPoints, writeupGrade } = this.props;
		if (prevProps.isOpen !== isOpen && isOpen) {
			this.setState({ submitting: false });
			if (writeupGrade >= 0) this.setState({ writeupGrade });
			else if (lastTeacherSolutionPoints > 0) this.setState({ writeupGrade: lastTeacherSolutionPoints });
			else this.setState({ writeupGrade: 0 });
		}

		// if solutionId has changed, reset form
		if (prevProps.solutionId !== this.props.solutionId) {
			this.clearForm();
		}
		if (!isEqual(this.props.criteriaCatalog?.criteriaGradingCatalogItems, prevProps.criteriaCatalog?.criteriaGradingCatalogItems)) {
			this.setState({
				criteriaList: this.props.criteriaCatalog?.criteriaGradingCatalogItems,
			});
		}
	}

	render() {
		const { isTeacher, lastSolutionComment, isOpen, closeModal, intl, classes, helpUrl, userFlags, isCriteriaGradingMode } = this.props;
		const { addGrade } = this.state;

		return (
			isOpen && (
				<ModalWindow
					open={true}
					onClose={closeModal}
					title={
						isTeacher
							? intl.fm("challenge.comment.newSolution.titleTeacher", null, { what: addGrade ? intl.fm("teacher.grading.grade") : intl.fm("teacher.grading.comment") })
							: intl.fm("challenge.comment.newSolution.title")
					}
					helpLink={isTeacher ? fetchHelp(helpUrl, "teacher", "correct") : fetchHelp(helpUrl, "student", "submitChallenge")}
					actionSection={this.commentActions()}
					fullWidth
					maxWidth={isCriteriaGradingMode ? "xl" : "md"}
				>
					{isTeacher && lastSolutionComment && lastSolution(lastSolutionComment, classes, intl)}
					{isTeacher && !isCriteriaGradingMode && <UserFlagsBox flags={userFlags} />}
					{this.commentForm()}
				</ModalWindow>
			)
		);
	}

	isNotSubmittable() {
		const { eventUnit, isTeacher, isCriteriaGradingMode } = this.props;
		const { flag, writeup, file, submitting, criteriaList } = this.state;
		const gradingMode = GRADING_MODES[eventUnit?.grading];

		const flagMissing = gradingMode.isFlagBased && !flag?.length > 0;
		const writeupMissing = gradingMode.isWriteupBased && !writeup?.length > 0 && file === null;
		const hasBothGradingModes = gradingMode.isFlagBased && gradingMode.isWriteupBased;
		const missingInput = (hasBothGradingModes && flagMissing && writeupMissing) || (!hasBothGradingModes && (flagMissing || writeupMissing));

		return submitting || (!isTeacher && missingInput) || (isTeacher && isCriteriaGradingMode && (!criteriaList || criteriaList.some((criteria) => criteria.fulfilled === undefined)));
	}

	commentActions() {
		const { intl, isTeacher, classes, nextSolutionId } = this.props;
		const { submitting } = this.state;
		const nextDisabled = !nextSolutionId;

		return (
			<div className={classes.actions}>
				<Button onClick={this.clearForm} color="defaultNoBackground">
					{intl.fm("common.labels.clearForm")}
				</Button>
				{isTeacher && (
					<Button disabled={nextDisabled} onClick={this.submitAndNext} color="infoNoBackground">
						{submitting ? <CircularProgress size={15} /> : intl.fm("common.labels.submitAndNext")}
					</Button>
				)}
				{isTeacher && (
					<Button onClick={this.submitAndBack} color="infoNoBackground">
						{submitting ? <CircularProgress size={15} /> : intl.fm("common.labels.submitAndBack")}
					</Button>
				)}
				<Button onClick={() => this.submit()} color="infoNoBackground">
					{submitting ? <CircularProgress size={15} /> : intl.fm("common.labels.submit")}
				</Button>
			</div>
		);
	}

	commentForm() {
		const { eventUnit, maxPoints, flagPoints, writeupWeight, isTeacher, intl, classes, criteriaCatalog, isCriteriaGradingMode, userFlags, gradingInstructions, solutionId, eventId } = this.props;
		const { flag, submitting, addGrade, formDirty, writeupGrade } = this.state;
		const gradingMode = GRADING_MODES[eventUnit?.grading];

		const sliderDisabled = submitting || !addGrade;

		if (isTeacher && !gradingMode.isWriteupBased && addGrade) {
			this.setState({ addGrade: false });
		}

		return isTeacher ? (
			<GradingForm
				isWriteupBased={gradingMode.isWriteupBased}
				maxPoints={maxPoints}
				flagPoints={flagPoints}
				writeupWeight={writeupWeight}
				writeupGrade={writeupGrade}
				writeup={this.state.writeup}
				file={this.state.file}
				criteriaGradingCatalog={criteriaCatalog}
				criteriaList={this.state.criteriaList}
				setCriteriaList={(criteriaList) => this.setState({ criteriaList: criteriaList })}
				isTeacher={isTeacher}
				isCriteriaGradingMode={isCriteriaGradingMode}
				addGrade={addGrade}
				submitting={submitting}
				sliderDisabled={sliderDisabled}
				handleWriteupGradeChange={(value) => this.setState({ writeupGrade: value })}
				handleIsAddGradeActiveChange={(isActive) => this.setState({ addGrade: isActive })}
				handleWriteupChange={(writeup) => this.setState({ writeup: writeup })}
				handleOnFileChange={(file) => this.setState({ file: file })}
				hasErrors={formDirty && this.isNotSubmittable()}
				userFlags={userFlags}
				gradingInstructions={gradingInstructions}
				eventId={eventId}
				solutionId={solutionId}
			/>
		) : (
			<SolutionSubmissionForm
				isFlagBased={gradingMode.isFlagBased}
				isWriteupBased={gradingMode.isWriteupBased}
				writeup={this.state.writeup}
				file={this.state.file}
				flag={flag}
				isTeacher={isTeacher}
				submitting={submitting}
				classes={classes}
				handleOnFileChange={(file) => this.setState({ file: file })}
				handleWriteupChange={(writeup) => this.setState({ writeup: writeup })}
				handleFlagTextChange={this.updateFlagText}
				hasErrors={formDirty && this.isNotSubmittable()}
			/>
		);
	}

	updateFlagText = (event) => this.setState({ flag: event.currentTarget.value });

	clearForm = () => this.setState({ file: null, flag: null, writeup: "", formDirty: false, criteriaList: getEmptyCriteriaList(this.state.criteriaList) });

	startSubmit = () => this.setState({ submitting: true });

	stopSubmit = () => this.setState({ submitting: false });

	submit = (callback) => {
		const { onSubmit, onSubmitGradingCriteriaList, challengeId, solutionId, onSuccess, isTeacher, closeModal, isCriteriaGradingMode } = this.props;
		const { flag, writeup, writeupGrade, file, addGrade } = this.state;

		if (!this.isSubmissionAllowed()) {
			// we only set the form to dirty (marking errors red) when submission is not allowed
			this.setState({ formDirty: true });
			return;
		} else {
			this.setState({ formDirty: false });
		}

		let attachment, entityId, commentDto;

		if (file) {
			attachment = {
				name: file.name,
				content: file.data,
			};
		}

		if (!isTeacher) {
			commentDto = {
				writeup: writeup?.length > 0 ? writeup.trim() : null,
				flag: flag?.length > 0 ? flag.trim() : null,
				attachment,
			};
			entityId = challengeId;
		} else {
			entityId = solutionId;
			commentDto = {
				comment: writeup?.length > 0 ? writeup.trim() : null,
				grade: addGrade ? writeupGrade : null,
				attachment,
			};
		}

		this.startSubmit();

		const criteriaCatalog = {
			id: this.props.criteriaCatalog?.id,
			criteriaGradingDescriptorsCatalogId: this.props.criteriaCatalog?.criteriaGradingDescriptorsCatalogId,
			name: this.props.criteriaCatalog?.name,
			criteriaGradingCatalogItems: this.state.criteriaList,
			attachment,
		};

		if (isCriteriaGradingMode && isTeacher) {
			onSubmitGradingCriteriaList(
				solutionId,
				criteriaCatalog,
				() => {
					this.clearForm();
					closeModal();
					if (onSuccess) onSuccess();
					if (callback) callback();
				},
				() => this.stopSubmit()
			);
		} else {
			onSubmit(
				commentDto,
				entityId,
				() => {
					this.clearForm();
					closeModal();
					if (onSuccess) onSuccess();
					if (callback) callback();
				},
				() => this.stopSubmit()
			);
		}
	};

	submitAndNext = () => {
		const { eventId, nextSolutionId, history } = this.props;
		const additionalCallback = () => history.push(`/teacher/events/${eventId}/solutions/${nextSolutionId}`);
		if (!this.isSubmissionAllowed()) {
			return;
		}
		this.submit(additionalCallback);
	};

	submitAndBack = () => {
		const { history, eventId } = this.props;
		const additionalCallback = () => history.push(`/teacher/events/${eventId}`);
		if (!this.isSubmissionAllowed()) {
			return;
		}
		this.submit(additionalCallback);
	};

	isSubmissionAllowed = () => {
		return !this.isNotSubmittable();
	};
}

SubmitDialog.propTypes = {
	eventId: PropTypes.number,
	eventUnit: PropTypes.object,
	challengeId: PropTypes.number.isRequired,
	maxPoints: PropTypes.number,
	solutionId: PropTypes.number,
	nextSolutionId: PropTypes.number,
	lastSolutionComment: PropTypes.object,
	lastTeacherSolutionComment: PropTypes.object,
	lastTeacherSolutionPoints: PropTypes.number,
	isOpen: PropTypes.bool.isRequired,
	writeupGrade: PropTypes.number,
	isTeacher: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onSubmitGradingCriteriaList: PropTypes.func.isRequired,
	onSuccess: PropTypes.func,
	closeModal: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
	criteriaCatalog: PropTypes.object.isRequired,
	helpUrl: PropTypes.string.isRequired,
	flagPoints: PropTypes.number,
	writeupWeight: PropTypes.number,
	gradingInstructions: PropTypes.object,
	userFlags: PropTypes.arrayOf(
		PropTypes.shape({
			timestamp: PropTypes.string.isRequired,
			flag: PropTypes.string.isRequired,
		})
	),
};

const lastSolution = (comment, classes, intl) => {
	return (
		<Accordion
			sections={[
				{
					title: intl.fm("teacher.solution.latestWriteup"),
					content: (
						<span>
							{comment.writeup}{" "}
							{comment.attachment && comment.attachment.id && (
								<a href={`/api/attachments/${comment.attachment.id}`} target="_blank" rel="noopener noreferrer" className={classes.lastCommentAttachment}>
									<AttachmentIcon className={classes.lastCommentAttachmentIcon} />
									{comment.attachment.name}
								</a>
							)}
						</span>
					),
				},
			]}
		/>
	);
};

const mapStateToProps = (state) => ({
	helpUrl: state.branding.helpUrl,
	darkMode: state.ui.darkMode,
});

export default compose(connect(mapStateToProps), withStyles(solutionStyle), withIntl, withRouter)(SubmitDialog);
